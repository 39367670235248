import React from 'react';
import { get } from '../../utils/request';
import { Spinner } from 'react-bootstrap';
import "../../dataProvider/buckets.js";
import Alert from "../../component/Alert/Alert";
import Button from "../../component/Button/Button";
import Layout from "../../component/Layout/Layout";
import Link from "../../component/Link/Link";
import DeleteBucket from "./DeleteBucket";
import {faBucket, faCogs} from "@fortawesome/free-solid-svg-icons";
import "../../component/Table/Table.scss"


class Buckets extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            buckets: [],
            deletedBucket: "",
            deleteErrorMessage: "",
            errorMessage: "",
            isItemDeleted: false,
            loading: true,
            loadingSuccess: false,
            successMessage: "",
        };
        this.loadData = this.loadData.bind(this);
    }

    updateDeleted = (name) => {
        this.setState({isItemDeleted: true});
        this.setState({deletedBucket: name});
        this.loadData();
    }

    async loadData() {
        const [res, err] = await get(this, "/auth/bucket/list");
        if (err) {
            this.setState({errorMessage: "error: " + err});
            this.setState({loading: false});
            return
        }
        this.setState({buckets: res.data.Buckets});
        this.setState({loading: false});
        this.setState({loadingSuccess: true});
        if (this.state.isItemDeleted) {
            this.setState({successMessage: "The bucket " + this.state.deletedBucket + " deletion was successfully queued."});
        }

        console.log(this.state.buckets)
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        const { buckets, errorMessage, loading, loadingSuccess, successMessage } = this.state;
        const { history } = this.props;

        const homepageUrl = `${process.env.REACT_APP_HOMEPAGE_URL}`;

        const cleanedBuckets = [];
        if (buckets) {
            buckets.forEach((item) => {
                let cleanedVersioning = item.versioning;
                if (!item.versioning) {
                    cleanedVersioning = 'Unversioned';
                }

                let objectLockStatus = 'Disabled';
                if (item.objectLock) {
                    objectLockStatus = 'Enabled';
                }
                cleanedBuckets.push({
                    name: item.name,
                    location: item.locationUid,
                    status: item.status,
                    versioning: cleanedVersioning,
                    objectLock: objectLockStatus,
                    isDeletable: item.isDeletable,
                    hasObjects: item.hasObjects
                });
            });
        }

        return <Layout activeMenu="/buckets" isLoggedIn={true}>
            <h1>Buckets</h1>
            {loading &&
            <>
                <Spinner animation="border" variant="primary"/> Loading buckets...
                <br/><br/>
            </>
            }
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            {successMessage && <Alert variant="success">{successMessage}</Alert>}
            {loadingSuccess &&
            <>
            <div className="d-flex">
                <Button className="primary btn btn-margin btn-right" faIcon={faBucket} label="Create
                bucket" onClick={() => history.push('/buckets/NewBucket')}/>
            </div>
            {!buckets &&
            <Alert variant="info">
                You don't have any buckets, yet.
                <br/>Bucket is like a high level folder and many programs need the bucket created before you can use it.
                <br/>Create at least one bucket.
            </Alert>
            }
                {buckets &&
                    <table className="table-ctr">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Location</th>
                            <th>Status</th>
                            <th>Versioning</th>
                            <th>Object Lock</th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {cleanedBuckets.map((value, index) => {
                            return <tr key={index}>
                                <td data-label={"Name"}>{value.name}</td>
                                <td data-label={"Location"}>{value.location}</td>
                                <td data-label={"Status"}>{value.status}</td>
                                <td data-label={"Versioning"}>{value.versioning}</td>
                                <td data-label={"Object Lock"}>{value.objectLock}</td>
                                <td><Link className="btn primary-outline" faIcon={faCogs} href={"/buckets/" + value.name + "/properties?location=" + value.location} label="View properties"/>
                                </td>
                                <td>
                                    {value.isDeletable && <DeleteBucket
                                        bucket={value.name}
                                        location={value.location}
                                        hasObjects={value.hasObjects}
                                        updateDeleted={this.updateDeleted}
                                    />}
                                </td>
                            </tr>
                        })}
                        </tbody>
                    </table>
                }
            <br/>
            <Alert variant="info">
                <b>For technical configurations, please consult our documentation:</b> <br/>
                <a href={homepageUrl+'/integrations'} target="_blank">Integration manuals</a> <br/>
                <a href={homepageUrl+'/docs/regions'} target="_blank">Regions</a>
            </Alert>
            </>
            }
        </Layout>;
    }
}

export default Buckets